import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import StorefrontIcon from '@mui/icons-material/Storefront';
import { motion, AnimatePresence } from 'framer-motion';

import './home.scss';
import logo from "./logo.png";
import load from "./load.gif";

import Sidebar from '../../components/sidebar/Sidebar';
import Navbar from '../../components/navbar/Navbar';
import CardPedido from '../../components/card/cardPedido'
import Button from '../../components/button/Button';

import PopUpAlerta from '../../components/popUpAlerta/PopUpAlerta'
import PopUpConfirmaRetorno from '../../components/popUpVazio/PopUpRetorno'
import { atualizaStatusRetorno } from '../../api/retornos';


const Check = () => {
  const { idMotorista } = useParams(); 
  const [retornos, setRetornos] = useState([]);
  const [popUpConfirmaRetorno, setPopUpConfirmaRetorno] = useState(false)
  const [conteudoPopUp, setConteudoPopUp] = useState("");
  const [popUpAlerta, setPopUpAlerta] = useState(false);
  const [step, setStep] = useState(1); 
  const [loader, setLoader] = useState(false);
  const [atualiza, setAtualiza] = useState(false);

  
  const [data, setData] = useState('');
  const [time, setTime] = useState('');

  const [bufferRetorno, setBufferRetorno] = useState("");

  useEffect(() => {
    if(atualiza === true){
      setAtualiza(false)
    }
    const fetchRetornos = async () => {
      try {
        atualizaRetornos(idMotorista, setRetornos);
      } catch (error) {
        console.error("Erro ao buscar retornos:", error);
      }
    };

    fetchRetornos();
  }, [idMotorista, setAtualiza, atualiza]);

  const handleConfirmaRetorno = (retorno) => {
    setBufferRetorno(retorno);
    setPopUpConfirmaRetorno(true);
  }

  const handleRetornou = async (id) => {
    try {
      await atualizaStatusRetorno(id, "motorista", {data: data, time: time});
      await atualizaRetornos(idMotorista, setRetornos);
      setPopUpConfirmaRetorno(false);
      setAtualiza(true)
    } catch (error) {
      setPopUpConfirmaRetorno(false);
      setConteudoPopUp(`Erro ao lidar com retorno de ID ${id}. Tente novamente.`);
      setPopUpAlerta(true);
      console.error("Erro ao lidar com retorno:", error);
    }
  }


  const lidarFechar = () => {
    setPopUpConfirmaRetorno(false)
    initialStep()
  };

  const nextStep = () => {
    setStep(step + 1);
  };

  const previousStep = () => {
    setStep(step - 1);
  };

  const initialStep = () => {
    setStep(1);
  };

  const slideVariants = {
    enter: { x: '100%', opacity: 0 },
    center: { x: 0, opacity: 1 },
    exit: { x: '-100%', opacity: 0 },
  };

  const handleChange = (event) => {
    setData(event.target.value);
  };

  const lidarComConfimacao = (event) => {
    var dataHoje = new Date()

    const year = dataHoje.getFullYear();
    const month = String(dataHoje.getMonth() + 1).padStart(2, '0'); // Mês começa em 0 (Janeiro = 0)
    const day = String(dataHoje.getDate()).padStart(2, '0'); // Garantir que o dia tenha 2 dígitos

    var dataFormatada = `${year}-${month}-${day}`


    setData(dataFormatada);

    nextStep()
  };

  const lidarComEnvio = (event) => {

    if(time === ''){
      alert('Selecione um horário.')
      return
    }
    nextStep()
    setLoader(true)

    handleRetornou(bufferRetorno._id)
    //setLoader(false)
  };

  const handleChangeHorario = (event) => {
    setTime(event.target.value);
  };


  return (
    <div className="home">
      <div className="homeCont">
        <Navbar select={"default"} selectList={"default"} selectOP={"default"} acesso={{userId: idMotorista}} />
        <Sidebar />
        <div className="content">
          <div>
            {retornos && Array.isArray(retornos) && retornos != undefined &&  retornos.length > 0 ? (
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {retornos &&
                    Array.isArray(retornos) &&
                    retornos.map((retorno, index) => (
                      <CardPedido 
                        key={index}
                        pedido={retorno.numero_pedido || "N/A"}
                        motorista={retorno.nomeEmpresa}
                        endereco={retorno.parada}
                        retorno={retorno.retorno}
                        onClick={() => handleConfirmaRetorno(retorno)}
                      />
                    ))}
              </div>
            ) : (
              <p>Não foram encontrados retornos em aberto.</p>
            )}
            <img className="logo" src={logo} style={{ opacity: 0.2, maxWidth: '100%' }} />
          </div>


          <PopUpConfirmaRetorno trigger={popUpConfirmaRetorno} setTrigger={setPopUpConfirmaRetorno} light={true} >
            {
                  <div style={{display:'flex', flexDirection: 'column', color: "#5a595c", width: 'auto' }}>
                     <i className="material-icons" onClick={() => {lidarFechar()}}>close</i>

                     {loader && (
                        <AnimatePresence>
                            <motion.div
                                className="loader-popup"
                                initial={{ opacity: 0, scale: 0.9 }}
                                animate={{ opacity: 1, scale: 1 }}
                                exit={{ opacity: 0, scale: 0.9 }}
                                transition={{ duration: 0.3 }}
                            >
                                <img className="icone" src={load} />
                            </motion.div>
                        </AnimatePresence>
                    )}

                     <AnimatePresence mode="wait">

                      {step === 1 && (
                        <motion.div
                            key="step1"
                            initial="enter"
                            animate="center"
                            exit="exit"
                            variants={slideVariants}
                            transition={{ duration: 0.5 }}
                            className="conteudo"
                        >
                          <div style={{ display: 'flex', flexDirection:'column'}}>
                            <div style={{ marginBottom: "10px" }}>
                              <strong>{"PEDIDO "}</strong>
                            </div>
                            <div style={{ width: 'auto' }}>
                                <div style={{ display: "flex", alignItems: "center", height: 'auto' }}>
                                  <StorefrontIcon style={{ marginRight: "5px" }} />
                                  {bufferRetorno.nomeEmpresa}
                                </div>
                                <div style={{ display: "flex", alignItems: "center", height: 'auto' }}>
                                  <PlaceOutlinedIcon fontSize="small" style={{ marginRight: "5px" }} />
                                  {bufferRetorno.parada}
                                </div>
                            </div>
                            <br />
                            <div style={{color: "#5a595c" , marginBottom:"10px"}}>
                              <p>{"Você deseja confirmar esse retorno?"}</p>
                            </div>
                            <div>
                              < Button
                                text='Não'
                                width='120px' 
                                borderRadius='8px' 
                                border='black' 
                                background='#c84333' 
                                color='white' 
                                onClick={()=>{setPopUpConfirmaRetorno(false)}}
                              /> 
                              < Button
                                text='Sim'
                                width='120px' 
                                borderRadius='8px' 
                                border='black' 
                                background='rgb(66, 175, 60)' 
                                color='white' 
                                onClick={()=>{lidarComConfimacao()} }
                              /> 
                            </div>
                          </div>
                        </motion.div>
                      )}

                      {step === 2 && (
                        <motion.div
                            key="step2"
                            initial="enter"
                            animate="center"
                            exit="exit"
                            variants={slideVariants}
                            transition={{ duration: 0.8 }}
                            className="conteudo"
                        >
                          <div style={{ display: 'flex', flexDirection:'column'}}>

                            <br />
                            <div style={{color: "#5a595c" , marginBottom:"10px"}}>
                              <p>{"Qual o dia que você retornou?"}</p>
                            </div>

                            <input
                              type="date"
                              id="data"
                              value={data}
                              onChange={handleChange}
                              style={{border: '1px solid rgb(127, 126, 128)', color: 'rgb(90, 89, 92)', borderRadius:'5px'}}
                            />
                            <div  style={{marginTop:'15px'}}>
                              < Button
                                text='Voltar'
                                width='120px' 
                                borderRadius='8px' 
                                border='black' 
                                background='#c84333' 
                                color='white' 
                                onClick={()=>{previousStep()}}
                              /> 
                              < Button
                                text='Avançar'
                                width='120px' 
                                borderRadius='8px' 
                                border='black' 
                                background='rgb(66, 175, 60)' 
                                color='white' 
                                onClick={()=>{nextStep()} }
                              /> 
                            </div>
                          </div>
                        </motion.div>
                      )}
                      {step === 3 && (
                        <motion.div
                            key="step3"
                            initial="enter"
                            animate="center"
                            exit="exit"
                            variants={slideVariants}
                            transition={{ duration: 0.8 }}
                            className="conteudo"
                        >
                          <div style={{ display: 'flex', flexDirection:'column'}}>

                            <br />
                            <div style={{color: "#5a595c" , marginBottom:"10px"}}>
                              <p>{"Qual horário aproximado que você retornou?"}</p>
                            </div>

                            <input
                              type="time"
                              id="time"
                              value={time}
                              onChange={handleChangeHorario}
                              style={{border: '1px solid rgb(127, 126, 128)', color: 'rgb(90, 89, 92)', borderRadius:'5px'}}
                            />
                            <div  style={{marginTop:'15px'}}>
                              < Button
                                text='Voltar'
                                width='120px' 
                                borderRadius='8px' 
                                border='black' 
                                background='#c84333' 
                                color='white' 
                                onClick={()=>{previousStep()}}
                              /> 
                              < Button
                                text='Avançar'
                                width='120px' 
                                borderRadius='8px' 
                                border='black' 
                                background='rgb(66, 175, 60)' 
                                color='white' 
                                onClick={()=>{lidarComEnvio()} }
                              /> 
                            </div>
                          </div>
                        </motion.div>
                      )}
                      {step === 4 && (
                        <motion.div
                            key="step4"
                            initial="enter"
                            animate="center"
                            exit="exit"
                            variants={slideVariants}
                            transition={{ duration: 0.8}}
                            className="conteudo"
                        >
                          <div style={{ display: 'flex', flexDirection:'column', width:'260px', height:'132px'}}>

                            
                          </div>
                        </motion.div>
                      )}

                     </AnimatePresence>
                   

                </div>
            }
          </PopUpConfirmaRetorno>

          <PopUpAlerta trigger={popUpAlerta} setTrigger={setPopUpAlerta}>
          {
            <div>
              {conteudoPopUp}
            </div>
          }
          </PopUpAlerta>
        </div>
      </div>
    </div>
  );
};

const atualizaRetornos = async (idMotorista, setRetornos) => {
  try {
    const response = await axios.get(`/retornos/getRetornosDoMotorista`, {
      params: { dados: idMotorista }
    });
    setRetornos(response.data);
  } catch (error) {
    console.error("Erro ao atualizar retornos:", error);
  }
}

export default Check;
